import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Particles from 'react-tsparticles';
import img_elMinted_text_black from './images/StartScreen_elMinted_text_black_378270.png';

export default class StartScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elTsparticles = {
      pointerEvents: 'auto',
     };
    const style_elMinted_text_black = {
      backgroundImage: 'url('+img_elMinted_text_black+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="AppScreen StartScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="elTsparticles" style={style_elTsparticles}>
            <Particles id="tsparticles" options={{"backgroundMask":{"cover":{"color":{"value":"#46d39a"}}},"particles":{"number":{"density":{"enable":true},"value":80},"color":{"value":"#46d39a"},"size":{"random":{"enable":true},"animation":{"speed":20,"minimumValue":0.1},"value":{"min":1,"max":3}},"links":{"enable":true,"color":{"value":"#ffffff"},"distance":150,"warp":true},"move":{"enable":true,"speed":6,"spin":{},"attract":{"rotate":{"x":600,"y":1200}},"outModes":{"bottom":"out","left":"out","right":"out","top":"out"},"path":{},"warp":true},"opacity":{"animation":{"speed":3,"minimumValue":0.1},"value":0.5}},"background":{"repeat":"no-repeat","color":{"value":"#ffffff"},"position":"50% 50%","size":"cover"},"fullScreen":{"zIndex":1},"interactivity":{"events":{"onClick":{"mode":"push"},"onHover":{"mode":"repulse"}},"modes":{"bubble":{"distance":400,"size":40,"duration":2,"opacity":0.8},"grab":{"distance":400}}}}} />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elMinted_text_black" style={style_elMinted_text_black} />
          </div>
        </div>
      </div>
    )
  }
  
}
